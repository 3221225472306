<template>
  <b-card>
    <!-- https://www.privacypolicygenerator.info/ -->
    <h1>INFORMACIÓN RELEVANTE</h1>
    <p> En storylabs, accesible desde app.storylabs.dev, una de nuestras principales prioridades es la privacidad de nuestros visitantes. Este documento de Política de privacidad contiene tipos de información recopilada y registrada por storylabs y cómo la usamos. </p>

    <p> Si tiene preguntas adicionales o necesita más información sobre nuestra Política de privacidad, no dude en contactarnos. </p>

    <p>
      Esta Política de privacidad se aplica solo a nuestras actividades en línea y es válida para los visitantes de nuestro sitio web con respecto a la información que compartieron y / o recopilaron en storylabs. Esta política no se aplica a ninguna información recopilada fuera de línea o a través de canales que no sean este sitio web.

      <!-- Nuestra Política de privacidad se creó con la ayuda del <a href="https://www.termsfeed.com/privacy-policy-generator/"> Generador de políticas de privacidad de TermsFeed </a>. -->
    </p>

    <h2> Consentimiento </h2>

    <p> Al utilizar nuestro sitio web, usted acepta nuestra Política de privacidad y acepta sus términos. </p>

    <h2> Información que recopilamos </h2>

    <p> La información personal que se le solicita que proporcione, y las razones por las que se le solicita que la proporcione, se le aclararán en el momento en que le solicitemos que proporcione su información personal. </p>
    <p> Si se comunica con nosotros directamente, es posible que recibamos información adicional sobre usted, como su nombre, dirección de correo electrónico, número de teléfono, el contenido del mensaje y / o los archivos adjuntos que pueda enviarnos, y cualquier otra información que elija proporcionar. . </p>
    <p> Cuando se registra para obtener una cuenta, es posible que le solicitemos su información de contacto, incluidos elementos como el nombre, el nombre de la empresa, la dirección, la dirección de correo electrónico y el número de teléfono. </p>

    <h2> Cómo usamos su información </h2>

    <p> Usamos la información que recopilamos de varias formas, que incluyen: </p>

    <ul>
      <li> Proporcionar, operar y mantener nuestro sitio web </li>
      <li> Mejorar, personalizar y expandir nuestro sitio web </li>
      <li> Comprender y analizar cómo utiliza nuestro sitio web </li>
      <li> Desarrolle nuevos productos, servicios, características y funcionalidades </li>
      <li> Comunicarnos con usted, ya sea directamente o a través de uno de nuestros socios, incluso para el servicio al cliente, para brindarle actualizaciones y otra información relacionada con el sitio web, y con fines promocionales y de marketing </li>
      <li> Enviarle correos electrónicos </li>
      <li> Busque y evite el fraude </li>
    </ul>

    <p>La información publicada por los usuarios y su correcto uso dependen exclusivamente de los mismos usuarios. No nos hacemos responsables por el uso indebido y fuera del objetivo de esta aplicación.</p>

    <div class="text-center">
      <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-toggle.showMore variant="outline-primary" @click="showMore=!showMore">
        Mostrar Más
      </b-button>
    </div>

    <b-collapse id="showMore" class="mt-2">
      <h2>Log Files</h2>

      <p> storylabs sigue un procedimiento estándar de uso de archivos de registro. Estos archivos registran a los visitantes cuando visitan sitios web. Todas las empresas de alojamiento hacen esto y forman parte del análisis de los servicios de alojamiento. La información recopilada por los archivos de registro incluye direcciones de protocolo de Internet (IP), tipo de navegador, proveedor de servicios de Internet (ISP), sello de fecha y hora, páginas de referencia / salida y posiblemente el número de clics. Estos no están vinculados a ninguna información que sea de identificación personal. El propósito de la información es analizar tendencias, administrar el sitio, rastrear el movimiento de los usuarios en el sitio web y recopilar información demográfica. </p>

      <h2> Cookies y balizas web </h2>

      <p> Como cualquier otro sitio web, storylabs usa 'cookies'. Estas cookies se utilizan para almacenar información, incluidas las preferencias de los visitantes y las páginas del sitio web a las que el visitante accedió o visitó. La información se utiliza para optimizar la experiencia de los usuarios al personalizar el contenido de nuestra página web en función del tipo de navegador de los visitantes y / u otra información. </p>

      <!-- <p>For more general information on cookies, please read <a href="https://www.generateprivacypolicy.com/#cookies">"Cookies" article from the Privacy Policy Generator</a>.</p> -->

      <h2> Políticas de privacidad de socios publicitarios </h2>

      <P> Puede consultar esta lista para encontrar la Política de privacidad de cada uno de los socios publicitarios de storylabs. </p>

      <p> Los servidores de anuncios de terceros o las redes de anuncios utilizan tecnologías como cookies, JavaScript o Web Beacons que se utilizan en sus respectivos anuncios y enlaces que aparecen en storylabs, que se envían directamente al navegador de los usuarios. Reciben automáticamente su dirección IP cuando esto ocurre. Estas tecnologías se utilizan para medir la eficacia de sus campañas publicitarias y / o para personalizar el contenido publicitario que ve en los sitios web que visita. </p>

      <p> Tenga en cuenta que storylabs no tiene acceso ni control sobre estas cookies que utilizan los anunciantes de terceros. </p>

      <h2> Políticas de privacidad de terceros </h2>

      <p> La Política de privacidad de storylabs no se aplica a otros anunciantes o sitios web. Por lo tanto, le recomendamos que consulte las respectivas Políticas de Privacidad de estos servidores de anuncios de terceros para obtener información más detallada. Puede incluir sus prácticas e instrucciones sobre cómo excluirse de determinadas opciones. </p>

      <p> Puede optar por deshabilitar las cookies a través de las opciones de su navegador individual. Para conocer información más detallada sobre la gestión de cookies con navegadores web específicos, se puede encontrar en los respectivos sitios web de los navegadores. </p>

      <h2> Derechos de privacidad de la CCPA (no vender mi información personal) </h2>

      <h2> Derechos de protección de datos del RGPD </h2>

      <p> Nos gustaría asegurarnos de que conoce todos sus derechos de protección de datos. Todo usuario tiene derecho a lo siguiente: </p>
      <p> El derecho de acceso: tiene derecho a solicitar copias de sus datos personales. Es posible que le cobremos una pequeña tarifa por este servicio. </p>
      <p> El derecho a la rectificación: tiene derecho a solicitar que corrijamos cualquier información que crea que es inexacta. También tiene derecho a solicitar que completemos la información que cree que está incompleta. </p>
      <p> El derecho a borrar: tiene derecho a solicitar que borremos sus datos personales, bajo ciertas condiciones. </p>
      <p> El derecho a restringir el procesamiento: tiene derecho a solicitar que restrinjamos el procesamiento de sus datos personales, bajo ciertas condiciones. </p>

      <p>
        Derecho a oponerse al tratamiento - Tiene derecho a oponerse a nuestra INFORMACIÓN RELEVANTE

        Es requisito necesario para la adquisición de los productos que se ofrecen en este sitio, que lea y acepte los siguientes Términos y Condiciones que a continuación se redactan. El uso de nuestros servicios así como la compra de nuestros productos implicará que usted ha leído y aceptado los Términos y Condiciones de Uso en el presente documento. Todas los productos que son ofrecidos por nuestro sitio web pueden ser creadas, cobradas, enviadas o presentadas por una página web tercera y en tal caso estarían sujetas a sus propios Términos y. En algunos casos, para adquirir un producto, será necesario el registro por parte del usuario, con ingreso de datos personales fidedignos y definición de una contraseña.

        El usuario puede elegir y cambiar la clave para su acceso de administración de la cuenta en cualquier momento, en caso de que se haya registrado y que sea necesario para la compra de alguno de nuestros productos. app.storylabs.dev no asume la responsabilidad en caso de que entregue dicha clave a terceros.

        Todas las compras y transacciones que se lleven a cabo por medio de este sitio web, están sujetas a un proceso de confirmación y verificación, el cual podría incluir la verificación del stock y disponibilidad de producto, validación de la forma de pago, validación de la factura (en caso de existir) y el cumplimiento de las condiciones requeridas por el medio de pago seleccionado. En algunos casos puede que se requiera una verificación por medio de correo electrónico.

        Los precios de los productos ofrecidos en esta Tienda Online es válido solamente en las compras realizadas en este sitio web.ng the internet. Alentamos a los padres y tutores a observar, participar y / o monitorear y guiar su actividad en línea.
      </p>

      <p> storylabs no recopila a sabiendas ninguna información de identificación personal de niños menores de 13 años. Si cree que su hijo proporcionó este tipo de información en nuestro sitio web, le recomendamos encarecidamente que se comunique con nosotros de inmediato y haremos nuestro mejor esfuerzo. para eliminar rápidamente dicha información de nuestros registros. </p>
    </b-collapse>
  </b-card>
</template>

<script>
import { BCard, BCollapse, BButton, VBToggle } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCollapse,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    VBToggle
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple
  },
  metaInfo: {
    title: 'Terminos de uso'
  },
  data() {
    return {
      showMore: false
    }
  }
}
</script>

<style>

</style>
